import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import 'es6-shim';

import LoginProvider from 'ibis-design-system/lib/Providers/LoginProvider';
import IEPage, {
    isUsingInternetExplorer,
} from 'ibis-design-system/lib/components/core/IEPage';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import ThemeProvider from 'ibis-design-system/lib/Providers/ThemeProvider';

import * as serviceWorker from './serviceWorker';
import Settings, { SetSettings } from './Settings';

const AppContainer = lazy(() => import('./AppContainer'));

if (isUsingInternetExplorer()) {
    ReactDOM.render(<IEPage />, document.getElementById('root'));
} else {
    let settingsPath = 'appSettings.json';
    if (process.env.NODE_ENV === 'development')
        settingsPath = 'appSettings.dev.json';

    HttpGet(`${window.location.origin}/${settingsPath}`).then((response) => {
        SetSettings(response);

        ReactDOM.render(
            <ThemeProvider>
                <LoginProvider
                    api={Settings.apiUrls.globalConfig}
                    ssoApi={Settings.apiUrls.singleSignOn}
                    sso={Settings.uiUrls.singleSignOn}
                    logApi={Settings.apiUrls.logService}
                    returnUrl={window.location.href}
                >
                    <Suspense
                        fallback={
                            <div style={{ height: '100vh' }}>
                                <LoadScreen />
                            </div>
                        }
                    >
                        <AppContainer />
                    </Suspense>
                </LoginProvider>
            </ThemeProvider>,
            document.getElementById('root')
        );
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
